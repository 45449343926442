import { logError } from 'fergy-core-react-logging';
import React, { type FunctionComponent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FORM_FIELD_TRACKING_MAP } from '../../../constants/customer';
import { AnalyticsHelper } from '../../../helpers/analytics/analytics.helper';
import { buildGTMLoginFailure, buildGTMValidationErrorTracking } from '../../../helpers/analytics/gtm/gtm-utils.helper';
import {
	emailInputRegistrationConfig,
	passwordInputRegistrationConfig
} from '../../../helpers/form-validation-helper/form-validation-helper';
import { useCustomerLogin } from '../../../hooks/apollo/authentication/authentication.hooks';
import { useRecaptcha } from '../../../hooks/recaptcha/recaptcha.hooks';
import { TextButton } from '../../buttons';
import { SubmitButton } from '../../buttons/submit-button/submit-button.component';
import { PanelComponent } from '../../common-components/panel-component/panel-component.component';
import { Recaptcha } from '../../common-components/recaptcha/recaptcha.component';
import { PasswordInput, TextInputWithLabel } from '../../inputs';
import { ReportIcon } from '../../svg/icons.component';

/**
 * Keys representing the inputs' `name` attributes
 */
type FormInputs = {
	username: string;
	password: string;
};

export type LoginFormProps = {
	onLoginSuccess?: () => void;
	onPasswordReset?: () => void;
	submitButtonText?: string;
	heading?: string;
};

export const LoginForm: FunctionComponent<LoginFormProps> = ({
	onLoginSuccess,
	onPasswordReset,
	submitButtonText = 'Log In',
	heading = 'Returning Customer'
}) => {
	const { login, loading: loginLoading } = useCustomerLogin();
	const [loginError, setLoginError] = useState('');
	const { getToken } = useRecaptcha('login');

	const onSubmit = async (formSubmissionData: FormInputs) => {
		setLoginError('');
		try {
			const token = await getToken();
			await login(formSubmissionData.username, formSubmissionData.password, token);
			if (onLoginSuccess) {
				onLoginSuccess();
			}
		} catch (error) {
			AnalyticsHelper.track(buildGTMLoginFailure()).catch(logError);
			setLoginError(error);
		}
	};

	const { register, handleSubmit, formState } = useForm<FormInputs>({ mode: 'onBlur' });

	useEffect(() => {
		if (formState.errors) {
			Object.keys(formState.errors).forEach((fieldName) => {
				AnalyticsHelper.track(buildGTMValidationErrorTracking(`login:${FORM_FIELD_TRACKING_MAP[fieldName] || fieldName}`)).catch(
					logError
				);
			});
		}
	}, [formState]);

	return (
		<PanelComponent headingContent={heading} className={'f4 br2'} headerClassName={'pa2'}>
			<form onSubmit={handleSubmit(onSubmit)} aria-label="login form">
				<TextInputWithLabel
					type="email"
					label="Email Address"
					placeholder="Email"
					autoComplete="username"
					className="b"
					data-testid="emailInput"
					required={true}
					invalid={Boolean(formState.errors.username)}
					invalidMessage={formState.errors.username?.message}
					{...register('username', emailInputRegistrationConfig)}
					automationHook="login-email"
				/>
				<PasswordInput
					id="password"
					label="Password"
					placeholder="Password"
					autoComplete="current-password"
					className="b mt2"
					data-testid="passwordInput"
					required={true}
					invalid={Boolean(formState.errors.password)}
					invalidMessage={formState.errors.password?.message}
					{...register('password', passwordInputRegistrationConfig)}
					automationHook="login-password"
				/>
				<Recaptcha />
				<div className={'pt3'}>
					<div className="flex flex-column flex-row-ns items-center">
						<SubmitButton
							buttonStyle="PRIMARY_ACCENT"
							automationHook={'login'}
							isBusy={loginLoading}
							busyText={'Logging in...'}>
							{submitButtonText}
						</SubmitButton>
						<div className="pl3 mt2 mt0-ns f5">
							<TextButton onClick={onPasswordReset} automationHook="forgot-password">
								Forgot Password?
							</TextButton>
						</div>
					</div>
				</div>
				{loginError && (
					<div className="flex items-center gc1 theme-error mt2 f5" data-testid="login-error">
						<ReportIcon className="minw1" />
						{loginError}
					</div>
				)}
			</form>
		</PanelComponent>
	);
};
