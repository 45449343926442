import { useMutation } from '@apollo/client/react/hooks';
import { logError } from 'fergy-core-react-logging';
import React, { type FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { type RequestPasswordResetMutation, type RequestPasswordResetMutationVariables } from '../../../__generated__/graphql-client-types';
import { emailInputRegistrationConfig } from '../../../helpers/form-validation-helper/form-validation-helper';
import { REQUEST_PASSWORD_RESET } from '../../../queries/authentication/authentication.queries';
import { StyledButton, TextButton } from '../../buttons';
import { PanelComponent } from '../../common-components/panel-component/panel-component.component';
import { TextInputWithLabel } from '../../inputs';

export type RequestPasswordResetFormProps = {
	onBack?: () => void;
};

type FormInputs = {
	emailAddress: string;
};

export const RequestPasswordResetForm: FunctionComponent<RequestPasswordResetFormProps> = ({ onBack }) => {
	const [resetSent, setResetSent] = useState(false);
	const [resetPassword] = useMutation<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>(REQUEST_PASSWORD_RESET);
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<FormInputs>({ mode: 'onBlur' });
	const onSubmit = (formData: FormInputs) => {
		resetPassword({ variables: { email: formData.emailAddress } })
			.then(() => {
				setResetSent(true);
			})
			.catch(logError);
	};
	return (
		<PanelComponent headingContent="Forgot your password?" className={'f4 br2'} headerClassName={'pa2'}>
			{resetSent ? (
				<>
					<span className="mr2" data-testid="complete-message">
						If there is an account associated with the email provided, you will receive an email with a link to reset your
						password.
					</span>
					<TextButton onClick={onBack}>Back to Login</TextButton>
				</>
			) : (
				<form onSubmit={handleSubmit(onSubmit)} aria-label="password reset form" data-testid="password-reset-form">
					<p className="mt1">Enter your email address and we will send you a confirmation email to reset your password.</p>
					<TextInputWithLabel
						label="Email Address"
						placeholder="Email"
						autoComplete="username"
						data-testid="emailInput"
						required={true}
						invalid={Boolean(errors.emailAddress)}
						invalidMessage={errors.emailAddress?.message}
						{...register('emailAddress', { ...emailInputRegistrationConfig })}
						automationHook="forgot-password-email"
					/>
					<div className="pt3 flex flex-column flex-row-ns justify-between items-center">
						<StyledButton buttonType="submit" automationHook="reset-password">
							Reset My Password
						</StyledButton>
						<div className="mt2 mt0-ns f5">
							<TextButton onClick={onBack}>Back to Login</TextButton>
						</div>
					</div>
				</form>
			)}
		</PanelComponent>
	);
};
