import useScript from 'react-script-hook';
import { GENERIC_ERROR } from '../../constants/message';
import { useSiteInfo } from '../apollo/site/site.hooks';

// Valid action names. Note: some of these are derived from
// Google's recommended list: https://cloud.google.com/recaptcha-enterprise/docs/actions-website
type Actions = 'signup' | 'login' | 'password_reset' | 'email' | 'order_lookup';

export const useRecaptcha = (action: Actions = 'email') => {
	const {
		data: {
			dataLayer: {
				keys: { recaptchaSiteId }
			}
		}
	} = useSiteInfo();

	useScript({
		src: `https://www.recaptcha.net/recaptcha/api.js?render=${recaptchaSiteId}`
	});

	return {
		getToken: (): Promise<string> => {
			return new Promise((resolve, reject) => {
				const grecaptcha = window.grecaptcha;
				if (typeof grecaptcha === 'undefined') {
					reject(GENERIC_ERROR);
					return;
				}
				grecaptcha.ready(() => {
					grecaptcha.execute(recaptchaSiteId, { action }).then(resolve, reject);
				});
			});
		}
	};
};
