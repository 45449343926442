import { type FetchResult } from '@apollo/client';
import { logError } from 'fergy-core-react-logging';
import { type ErrorFieldsFragment } from '../../../client/__generated__/graphql-client-types';
import { GENERIC_ERROR } from '../../constants/message';
import { useNotification } from '../notification/notification.hooks';

export type HandleResultOptions = {
	successMessage?: string;
	genericErrorMessage?: string;
	errorMessageHideTimeout?: number;
	onError?: (message: string, code: string) => void; // called if mutation returns a `Error` type
	onApolloError?: (message: string) => void; // called if response contains Apollo errors
	onSuccess?: () => void; // called if response contained no errors
	errorCallback?: () => void; // callback fired within onError and onApolloError
};

/**
 * Hook meant to simplify handling of logging and notification of errors/success states
 * in a mutation result
 */
export function useHandleMutationResult() {
	const { notifyDanger, notifySuccess } = useNotification();

	return {
		/**
		 * Handle errors/success in a mutation fetch result
		 *
		 * @param result - the mutation result
		 * @param options - options for messages etc.
		 */
		handleResult: (result: FetchResult, options: HandleResultOptions = {}) => {
			const { successMessage, genericErrorMessage, errorMessageHideTimeout, errorCallback } = options;
			const optDefaults: Omit<Required<HandleResultOptions>, 'errorCallback' | 'successMessage' | 'errorMessageHideTimeout'> = {
				genericErrorMessage: GENERIC_ERROR,
				onError: (errMessage) => {
					notifyDanger(errMessage, errorMessageHideTimeout);
					if (errorCallback) {
						errorCallback();
					}
				},
				onApolloError: (apolloMessage) => {
					notifyDanger(genericErrorMessage || GENERIC_ERROR, errorMessageHideTimeout);
					logError(apolloMessage);
					if (errorCallback) {
						errorCallback();
					}
				},
				onSuccess: () => {
					if (successMessage) {
						notifySuccess(successMessage);
					}
				}
			};

			const { onError, onApolloError, onSuccess } = Object.assign({}, optDefaults, options);

			const error: ErrorFieldsFragment | undefined = Object.values(result.data || {}).find((value) => value?.__typename === 'Error');

			let isError = false;
			let code: string | undefined;
			let message: string | undefined;

			if (error?.__typename === 'Error') {
				// Error type returned
				isError = true;
				code = error.code;
				message = error.message;
				onError(message, code);
			} else if (result.errors?.length) {
				// Apollo error
				isError = true;
				onApolloError(result.errors[0].message);
			}

			if (!isError) {
				onSuccess();
			}

			return {
				isError,
				code,
				message
			};
		},
		/**
		 * Handle exception (promise reject) from mutation call
		 *
		 * @param error - error message
		 */
		handleException: (error?) => {
			notifyDanger(GENERIC_ERROR);
			if (error) {
				logError(error);
			}
		}
	};
}
